import { createInertiaApp, Link } from '@inertiajs/inertia-react';
import { mdiAlert, mdiExclamation, mdiSync } from '@mdi/js';
import { Icon } from '@mdi/react';
import {
  AccountMenu,
  formatShortDatetime,
  FormErrorContextProvider,
  InitialApplicationContext,
  MainLayout,
  PrimaryButton,
  ThemeContext,
} from '@webfox/webfox-ui';
import cls from 'classnames';
import { differenceInMinutes } from 'date-fns';
import route from 'laravel-routes';
import * as React from 'react';
import { createElement, ReactElement, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import 'Style/app.css';
import theme from 'tailwind-theme';
import { accountMenuItems, navItems } from './MainLayoutConfig';

function ErrorFallback() {
  return (
    <div className="max-w-screen-3xl -mt-20 flex h-full w-full flex-col items-center justify-center text-gray-800">
      <div>
        <Icon path={mdiExclamation} className="h-20 w-20 rounded-full bg-orange-200 text-orange-700" />
      </div>
      <div className="mt-3 text-2xl font-medium sm:mt-5">Whoops! Something went wrong.</div>
      <div className="mt-2">Developers have been notified.</div>
      <div className="mt-5 flex gap-4 sm:mt-6">
        <PrimaryButton onClick={() => window.location.reload()}>Reload</PrimaryButton>
        <Link href={route('logout')}>
          <PrimaryButton>Logout</PrimaryButton>
        </Link>
      </div>
    </div>
  );
}

createInertiaApp<Window['inertiaInitialPageData']['props']>({
  resolve: async (name) => {
    const comps = import.meta.glob('./Pages/**/*.jsx');
    const match = comps[`./Pages/${name}.jsx`];
    return (await match()).default;
  },
  page: window.inertiaInitialPageData,
  setup({ el, App, props }) {
    createRoot(el).render(
      <StrictMode>
        <InitialApplicationContext.Provider value={{ application: props.initialPage.props.application }}>
          <ThemeContext.Provider value={theme}>
            <App {...props}>
              {({ key, props, Component }) => {
                // @ts-ignore
                const componentLayout: (page: ReactElement) => ReactElement | Array<(page: ReactElement) => ReactElement> = Component.layout;
                const page = createElement(Component, { key, ...props });

                const lastSync = page.props.application.last_sync?.value || null;
                const longTimeNoSync = !lastSync || differenceInMinutes(new Date(lastSync), new Date()) < -30;

                const { user } = page.props.application;

                let children;

                const filteredNavItems = user
                  ? navItems
                      .filter((item) => item?.users?.includes(user.role.value) || !item.users)
                      .map((item) => ({
                        ...item,
                        sub: item?.sub?.filter((subItem) => subItem?.users?.includes(user.role.value) || !item.users),
                      }))
                  : [];

                if (Array.isArray(componentLayout)) {
                  children = componentLayout
                    .concat(page)
                    .reverse()
                    .reduce((children, Layout) => createElement(Layout, { children }));
                } else if (typeof componentLayout === 'function') {
                  children = componentLayout(page);
                } else {
                  children = (
                    <MainLayout
                      accountMenu={
                        <>
                          <div className={cls('flex gap-2', longTimeNoSync ? 'text-red-500' : 'text-green-500')}>
                            <Icon path={longTimeNoSync ? mdiAlert : mdiSync} size={1} />
                            {lastSync ? formatShortDatetime(lastSync) : 'Not synced yet'}
                          </div>
                          <AccountMenu accountMenuItems={accountMenuItems} />
                        </>
                      }
                      children={<ErrorBoundary FallbackComponent={ErrorFallback}>{page}</ErrorBoundary>}
                      largeLogoSrc="/logo.png"
                      navItems={filteredNavItems}
                      notificationPosition="bottom-right"
                      smallLogoSrc="/cnc-small-dark.png"
                    />
                  );
                }

                return <FormErrorContextProvider>{children}</FormErrorContextProvider>;
              }}
            </App>
          </ThemeContext.Provider>
        </InitialApplicationContext.Provider>
      </StrictMode>
    );
  },
});
