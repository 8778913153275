import { mdiAccount, mdiAccountTie, mdiCog, mdiFolder, mdiHome, mdiListBox, mdiLogout, mdiTruck } from '@mdi/js';
import route from 'laravel-routes';

export const navItems = [
  {
    title: 'Dashboard',
    href: route('dashboard'),
    icon: mdiHome,
    active: 'dashboard',
    users: ['admin', 'etl', 'etl_admin', 'dispatch'],
  },
  {
    title: 'ETL',
    href: route('confirm.index'),
    icon: mdiTruck,
    active: ['confirm.*', 'consignments.*', 'rates.*'],
    users: ['etl', 'etl_admin'],
  },
  {
    title: 'ETL',
    href: route('consignments.index'),
    icon: mdiTruck,
    active: ['confirm.*', 'consignments.*', 'rates.*'],
    users: ['admin', 'dispatch'],
  },
  {
    title: 'Customers',
    href: route('customers.index'),
    icon: mdiAccountTie,
    active: 'customers.*',
    users: ['admin', 'dispatch', 'etl', 'etl_admin'],
  },
  {
    title: 'Jobs',
    href: route('work-orders.index', 'general'),
    icon: mdiFolder,
    active: ['work-orders.*'],
    users: ['admin', 'dispatch'],
  },
  {
    title: 'Work Order Status',
    href: route('work-order-status', 'today'),
    icon: mdiListBox,
    active: 'work-order-status',
    users: ['admin'],
  },
  {
    title: 'Manage',
    icon: mdiCog,
    users: ['admin', 'etl_admin'],
    sub: [
      {
        title: 'Users',
        href: route('users.index'),
        icon: mdiAccount,
        active: 'users.*',
        users: ['admin', 'etl_admin'],
      },
      {
        title: 'Settings',
        href: route('settings.index', 'email'),
        icon: mdiCog,
        active: 'settings.*',
        users: ['admin'],
      },
    ],
  },
].filter((item) => typeof item.filter !== 'function' || item.filter());

export const accountMenuItems = [
  {
    title: 'Log out',
    href: route('logout'),
    icon: mdiLogout,
  },
];
